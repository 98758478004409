const moment = require('moment-timezone');

const releases = [

  {
    title: '',
    version: '2.1.1',
    tags: [],
    date: moment('2023-10-24T17:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '業務フローのデータ読み込み改善',
        sammery:
        'APIのレスポンスデータ量上限にかかるようになったため分割取得に切り替え対応しました。ローディング画面をつけ進捗がわかるようにしました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      }
    ],
  },
  {
    title: '',
    version: '2.1.0',
    tags: [],
    date: moment('2023-08-03T17:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: 'チケット196対応',
        sammery:
        '予実管理で表示されるチームの予算が２倍になっているバグを修正しました。原因はタイムゾーンの関係で９時間ずれるため、開始日終了日が９時間ずれ「月期間」が２か月となってしまうことが原因でした。そのほかエラーハンドリング等修正しています',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      }
    ],
  },
  {
    title: '',
    version: '2.0.0',
    tags: [],
    date: moment('2023-02-09T17:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '.Net6.0対応',
        sammery:
          'AWS Lambda が旧バージョンに対応しなくなるため、フレームワークのバージョンアップを行いました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      }
    ],
  },
  {
    title: '',
    version: '1.2.10',
    tags: [],
    date: moment('2023-01-27T17:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: 'エラーメッセージがわかりづらい',
        sammery:
          'ログインセッション切れも含めたすべてのAPIとの通信エラーを「通信中にエラーが発生しました」になっているためわかりづらいと意見がありました。その中で、ログインセッション切れは操作ミスやサーバエラーでなく起こりうるエラーのため、処理を変更しました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      }
    ],
  },
  {
    title: '',
    version: '1.2.9',
    tags: [],
    date: moment('2023-01-09T17:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: 'CSVで集計データを出力したい',
        sammery:
          'まずはモックアップとしてフローページにボタンを追加し基本的なデータを出力できるようにしました。項目の拡張や変更などは随時行っていきます。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'CSVで集計データを出力したい(追)',
        sammery: 'HP制作費、システム初期導入費などの現金・レンタル売上列を追加し、請求書発行日などの項目も追加しました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '「支店長チーム」を作成したい',
        sammery:
          'チーム編集に「支店長チーム」という属性を付加できるようになりました。各支店のチームの先頭に並び順が自動で設定され、**各項目の目標(予算)**が0円であればその項目行が表示されなくなります。※あくまで行を消すだけなので、売上見込みなどが上がれば合計値に反映されます。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '予実管理を行いたい',
        sammery: '本機能を実装しました。メインメニューに「予実管理」を追加されました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },

  {
    title: '',
    version: '1.2.8',
    tags: [],
    date: moment('2022-11-15T17:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '旅館事業部の売り上げ見込み入力、および集計表示の売上見込み、達成率項目の追加',
        sammery: '追加しました。',
        bugZillaTickets: [],
        openProjectTickets: [888],
        memo: '',
      },
    ],
  },
  {
    title: '',
    version: '1.2.7',
    tags: [],
    date: moment('2022-11-07T17:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: 'デモ報告フオームのオプションに宿ぷらす+の良選企画項目追加',
        sammery: '追加しました。メール配信の文面にもオプション項目に本項目が追記されるようになっています',
        bugZillaTickets: [],
        openProjectTickets: [889],
        memo: '',
      },
    ],
  },

  {
    title: '',
    version: '1.2.6',
    tags: [],
    date: moment('2022-09-26T17:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '権限設定パネルを追加',
        sammery:
          'ユーザに対して機能別の権限を付与できるようになりました。「システム管理者」「支店長」など、いわゆる概念上の権限グループを作成し、それぞれにいずれの機能を所持させるかのプリセットを設定させています。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '既存権限操作排除',
        sammery:
          '既存の権限機能がユーザ編集画面に残っていたため、排除し新しい権限機能とバッティングしないようにしました。ただし、現行の権限状態はこの設定内容を見ているため、新しい権限の機能表が更新されたタイミングで制御箇所への更新も行われるようになります',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'ログイン回りブラッシュアップ',
        sammery: '操作放置状態から復旧時のログイン復旧処理をブラッシュアップしました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '処理時間調整',
        sammery:
          'WEBAPIよりレスポンスの待機時間が20秒前後となる箇所を見直しました。今回の対象箇所は、「売上進捗」「売上伝票」「施設管理」「マスタ」です。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '受信容量調整',
        sammery:
          'WEBAPIよりレスポンス内容が5MBを超えている、もしくは超えそうな部分を見つけ次第その画面に不要と思われるデータを送信させないようにしデータ軽減処理を行っています',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'その他ブラッシュアップ',
        sammery:
          'レスポンシブ時のレイアウトを調整し、著しく横幅が詰まるレイアウトとなっていたところを見つけ次第程よく表示させるように調整しました。ソースコード中のハードコーディング箇所を定義ファイルにリファクタリングしました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },
  {
    title: '',
    version: '1.2.5',
    tags: [],
    date: moment('2022-08-25T17:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '受注報告のオプションに項目追加',
        sammery:
          '対応しました。あすなろプラスの項目を追加されています。複数選択可能です（これまでは旧来のフォーム項目を再利用しているため（〇〇＋△△）という項目を選択していましたが、単純に複数選択可能なプルダウンとなっています）',
        bugZillaTickets: [],
        openProjectTickets: [668],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'リース結果:ＮＧとして新規登録できない（料率等が必須になっている）・・・編集も同様',
        sammery: '変更しました',
        bugZillaTickets: [],
        openProjectTickets: [673],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'リース結果まとめて登録を新規登録時にも設置し編集と同様の入力ができるようにすること',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [674],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '導入取材報告の「導入取材完了日」は予定日をデフォルトで入っているように',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [675],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '残債、他社買取、サービス品がすべてラベルが「残債」になっている',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [676],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'フロー:リースOKの物件一覧を表示できるように',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [677, 678],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '受注物件状況一覧画面>項目名修正、表示データ項目修正',
        sammery:
          '審査中、未導入物件、導入決定物件、リース開始確認待ちのそれぞれの状態に応じ、「導入予定日」や「導入日」に表示がかわるように修正しました',
        bugZillaTickets: [],
        openProjectTickets: [733],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業マージン集計表>社員で絞り込んだ際、他者の情報を表示しない',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [734],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'リース審査結果登録の項目名、フロー修正',
        sammery:
          '対応しました 詳細仕様として「報告」にチェックをいれ、さらにOK・NGにした場合”リース審査中”以降のフローに進みます。（審査結果には提出待ちという初期状態もあるので、チェックを入れてこの状態であれば「リース審査中」のままになります）',
        bugZillaTickets: [],
        openProjectTickets: [738],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'リース審査中>リース審査結果登録画面 背景に不要なものが表示する',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [739],
        memo: '',
      },
      {
        no: '',
        product: '',
        title:
          'すけさん売上の集計仕様に関して:毎月各週あたま時点の、売上見込をスナップショットとして取得しすけさん手数料売上の進捗状況を把握しやすくする',
        sammery:
          '実装しました 取得タイミングはバッチ処理で月の1日、8日、15日、22日、29日(2月以外)の0：00に取得されます。取得されていない場合の緊急対応策として隠しコマンドで表示される手動実行ボタンを用意しています',
        bugZillaTickets: [],
        openProjectTickets: [743],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '売上進捗の「すけさん実績更新」が正常に完了しない（更新されない）',
        sammery:
          '対応しました。全支店一括処理のため処理時間が30秒を超えておりAWS側でタイムアウトしていました。このタイムアウト時間はAWS側で29秒を超えて変更できないため、支店一括処理ではなく各支店にバラして処理させるようにしました',
        bugZillaTickets: [],
        openProjectTickets: [767],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '小数点第二位が0の場合でも省略せず0埋めし表示させたい',
        sammery: '対応しました パーセント表示している全箇所が小数点２位まで０埋めで表示されるようになりました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'いずれのリース会社でのリースOK/NG状況がわかるようにしたい。また、全リースNGとなったのかを区別してフローに表示させたい',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'ヘルプ機能に設置していたマージン計算機を廃止しました',
        sammery: 'メンテナンスが追いつかない状態のままであったため廃止しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },

  {
    title: '',
    version: '1.2.4',
    tags: [],
    date: moment('2022-07-20T14:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '重複する宿情報のマージ',
        sammery: '本バージョンではしかかり中ですが、本チケットの一環として、施設一覧の内容をリニューアルさせ重複機能検索をつけました。',
        bugZillaTickets: [],
        openProjectTickets: [538],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '営業フロー報告>入金待ち画面の表示項目編集',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [639],
        memo: '',
      },
    ],
  },
  {
    title: '',
    version: '1.2.3',
    tags: [],
    date: moment('2022-07-11T14:02:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '<未提出書類>ですけさん契約申込書・ワイドネットがチェック項目非表示物件がある',
        sammery: '対応しました。対応表はOpenProjectを参考してください。',
        bugZillaTickets: [],
        openProjectTickets: [613],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '<入金済>の表示が他の項目と違います(たぶんリース会社が縦表記になっているため)。統一してほしいです。',
        sammery:
          '対応しました。オリ、クレディ、三井住友、その他　（元の表記オリックス、クレディセゾン、三井住友ファイナンス、その他）に変更し、要望に沿った形式で最大４文字を目安に短縮しています',
        bugZillaTickets: [],
        openProjectTickets: [612],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '<導入日決定物件>に移ったお宿が導入日延期なった場合、もう一度<導入日未定>へ移したい。',
        sammery:
          '対応しました。「導入日日程変更」画面に**導入日程クリア**ボタンを設けました。業務フロー側にもFA側と同様の導入日程変更機能を設置しました（導入日決定物件中から操作できます）',
        bugZillaTickets: [],
        openProjectTickets: [611],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '<未提出書類>ですけさん契約申込書・ワイドネットがチェック項目非表示物件がある',
        sammery:
          'この変更で、受注条件のオプションのすけさん契約にチェックをつけた場合、「すけさん契約」と判別されるように仕様が変わりました（これまでシステム=「その他」と指定する必要もありました）',
        bugZillaTickets: [],
        openProjectTickets: [613],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業フロー報告>未提出書類画面の物件リストから、「入力ミス」を除外する',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [627],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '[追加分]項目名編集、引用する内容変更　その他→受注区分：リプ/新規　の情報を引用　業種→列を削除',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [598],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '要望：新規アポ、即デモ報告入力時、顧客情報を顧客名からテキスト検索で入力したい',
        sammery:
          '試作として新規アポにのみ対応しました 従来通り電話番号(ハイフン抜き)に加え、宿名**など**で検索できるようにし、結果には同名施設の区別として住所、電話番号が表示されます（管轄支店に関しては現時点で仕様ではないため実装なし）',
        bugZillaTickets: [],
        openProjectTickets: [625],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業フロー>導入・取材完了　画面のリストをフィルタリングし必要な情報を表示、検索したい',
        sammery: '対応しました。業務フロー画面の上部に「詳細検索」を設置し、クリックで展開・使用できます。',
        bugZillaTickets: [],
        openProjectTickets: [628],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '売上進捗>売上金額の「残債、買取、サービス品」差引後の金額は1円単位まで計算する',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [630],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '項目追加：売上進捗画面>営業部の集計項目として「宿ぷらす(業販)」を追加する',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [633],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'WEBAPIからの転送容量に対する改善が必要',
        sammery: 'ユーザダッシュボードへの対応を行いました。他の箇所への対応は仕様確定もしくは発生した都度の対応となります。',
        bugZillaTickets: [],
        openProjectTickets: [634],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '「売上進捗」の事業部別集計の”補正”について',
        sammery:
          '小計「補正」は、チームの予算編集で小計（補正）を入力していた場合その値、空や未設定の場合は同じくチーム予算編集で入力した各種予算の内訳合計＝小計で計算されるようになりました。※これまでは単純に小計（補正）を合計していたため、未入力のチームは０円として合計されていました。￥',
        bugZillaTickets: [],
        openProjectTickets: [635],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'フォーム関連内容リフレッシュ',
        sammery: ' 旧来の不要なフォームをなくし、宿ぷらすなどの新しいフォームへのリンクの掲載したバージョンへリフレッシュしました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'ブラッシュアップ',
        sammery: ' レイアウト調整等微細な修正を行いました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },
  {
    title: '',
    version: '1.2.2',
    tags: [],
    date: moment('2022-06-13T9:32:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '受注物件状況一覧　(画面追加)',
        sammery: '対応しました。新しい権限が必要なため、ユーザ編集から該当する権限を付与させるとメニューに表示されるようになります。',
        bugZillaTickets: [],
        openProjectTickets: [598],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '仕様変更：営業マージン集計の元となる金額計算式の変更',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [624],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'ブラッシュアップ',
        sammery: ' レイアウト調整等微細な修正を行いました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },
  {
    title: '',
    version: '1.2.1',
    tags: [],
    date: moment('2022-06-10T9:32:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '営業マージン成績表追加',
        sammery:
          '[xx期x月分部給.xls](=支払いマージン明細書)をベースに、営業マージンの報酬金額等の情報を登録できる機能が実装されました。登録された営業マージン情報は各自ダッシュボードの「営業成績確認」ボタンから確認することができます。また、本システムが稼働する前などのシステムに未入力のアポやデモに関するマージン情報も手動で登録できるようになっています。ただし、ここで入力された営業情報は他の営業情報と異なりフローなどの管理情報に記載されない単なるマージン情報の付加情報なのでご注意ください。',
        bugZillaTickets: [],
        openProjectTickets: [525],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '報告区分「リプデモ」追加',
        sammery:
          ' 報告区分に本項目が追加されました。リプデモを選択しても受注区分で**リプレイス**を選択しないとリプレイス扱いになりませんのでご注意ください。この特殊な仕様については別途チケットに記載しています。',
        bugZillaTickets: [],
        openProjectTickets: [523, 524],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'リース買い取り金額(3項目)の入力簡略化のリンク追加',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [522],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '数値入力箇所の空登録時のエラー？について',
        sammery:
          ' 正しくnullとして処理されるようにしました。これまでは手入力後Backspace等で削除してもnullにならず空文字になってしまっていました。（クリアボタンを押せばnullになるようにはなっていました）',
        bugZillaTickets: [],
        openProjectTickets: [519],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '売上進捗>各チーム内訳の内容修正',
        sammery:
          '修正しました。 売上進捗->内訳->開始確認 で、リース開始確認のステータスが取得されていなかったため「確認済み」とならず、リース確認済みでもその前のステータスである「導入済み」で表示されていました。）',
        bugZillaTickets: [],
        openProjectTickets: [435],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '売上進捗の期間、予算入力の設定月を任意の年月で指定したい',
        sammery:
          '任意の年月で選択できるようになりました。これまでは現時点より前後数か月の固定範囲での指定であったため、それ以外の年月の指定ができませんでした。',
        bugZillaTickets: [],
        openProjectTickets: [555],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業マージン集計表：画面を開いた時のデフォルトの表示を前月にする',
        sammery:
          '対応しました。期をまたぐ場合は前期が指定されます。また、「絞り込み」で開始月と終了月がありましたが、月タブ指定など仕様が競合し混乱するため廃止となりました',
        bugZillaTickets: [],
        openProjectTickets: [595],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '営業フロー報告>リース審査中のリストに受注キャンセルがリストアップされている',
        sammery: '表示されないように変更しました',
        bugZillaTickets: [],
        openProjectTickets: [596],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業マージン集計表、デモを[業務承認済]にすると、率が0.04%と表示される',
        sammery: '対応しました。実装漏れです。この部分に入力値->パーセント　に表記変換処理の実装がされていませんでした',
        bugZillaTickets: [],
        openProjectTickets: [601],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '業務承認ができない',
        sammery:
          '対応しました。いずれも料率やマージン金額がない場合、値チェックにより承認できない仕様でしたが、0を許容するようにしました。',
        bugZillaTickets: [],
        openProjectTickets: [602, 603],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '各個人の「営業成績確認」画面、デフォルトで表示される営業マージン成績表の対象月を前月にして欲しい',
        sammery: '対応しました　595と同様の対応を施しています',
        bugZillaTickets: [],
        openProjectTickets: [606],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'ブラッシュアップ',
        sammery: ' その他、指摘事項やレイアウト調整を行っています',
        bugZillaTickets: [],
        openProjectTickets: [532, 533, 534, 527, 540, 541, 551, 545],
        memo: '',
      },
    ],
  },
  {
    title: '',
    version: '1.2.0',
    tags: [],
    date: moment('2022-05-04T16:32:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '営業ポイント成績表追加',
        sammery:
          '「xx期x月分歩合(version 2).xls」をベースに、デモ・アポ（新規、リプ）・すけさん・レンタル・やどーだ・スマホに関する営業報酬金額の自動算出、調整、業務承認を行えます。業務承認が得られた報酬内容はログインしているマイページより確認できます。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '他社買取、サービス品に関する情報を登録できるようになりました。',
        sammery: ' デモの編集画面から「残債」と同様に入力され、売上金額に影響します',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '売上進捗「すけさん実績更新」ボタンの下に最終取得日時を表記してほしい',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [484],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'その他ブラッシュアップ',
        sammery: ' レイアウト調整、処理速度改善を施しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },

  {
    title: '',
    version: '1.1.9',
    tags: [],
    date: moment('2022-04-22T10:08:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '営業ポイント機能 新規追加',
        sammery:
          'メインメニューに「営業ポイント成績表」が加わりました。基本的にエクセルの営業ポイント成績表と全く同様の機能を有しています<br /> *利用に際して<strong>マスタ</strong>よりユーザに権限付与が必要になります。<br /> *表示されている社員に関して　登録している社員ではなく、ポイント計算に関係している社員（アポインター、デモ担当）のみ表示されています',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'リース審査結果登録画面、料率入力部分で「見込み金額」を表示してほしい',
        sammery: '対応しました。上部に表示されているデモ情報内にも売上見込み金額を表示するようにしています',
        bugZillaTickets: [],
        openProjectTickets: [463],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業フロー報告>見出し、タイトルの修正',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [439],
        memo: '',
      },

      {
        no: '',
        product: '',
        title:
          '営業フロー報告>デモOK一覧の表示レイアウトに関して、Edgeにて確認したところ表示内容が収まらなかったため、表示レイアウトの調整をお願いします。',
        sammery:
          '長い宿名（宿名＋件名＋地域名）は短縮表示させ横幅を短くしました。見えなくなった情報をカバーするためマウスオーバーで隠れた情報が表示されるように修正しています',
        bugZillaTickets: [],
        openProjectTickets: [474],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業フロー報告>デモOK一覧に「ダミー」宿のデモ受注結果が表示されている',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [475],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '売上進捗に関して、長大なので不要な項目をいくつか削除(非表示)にしてほしい',
        sammery: '修正しました　各支店の合計の表の下の「すけさん売上」「内訳」を非表示にしました',
        bugZillaTickets: [],
        openProjectTickets: [476],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '売上進捗：事業部毎の合計表を追加(既存のエクセル表と同様の表)',
        sammery:
          '売上進捗に「営業部」「旅館事業部」の合計を表示するようにしました。支店編集から**種別**を営業部、旅館事業部に設定が必要です',
        bugZillaTickets: [],
        openProjectTickets: [477],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '予算を実績の値で更新機能は不要になったため排除。本機能は使用開始期間のための便利機能であったので期間終了に伴う変更として',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'パーセントバー表記を数値表記に変更してほしい',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '[売上進捗]チームの月実績手入力の値と実績値が足されて計上されている',
        sammery:
          '修正しました。月実績手入力が優先され、そちらの入力がある場合実績値は無視されるのが正しい仕様ですが、この仕様が実装されていませんでした',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '[ダッシュボード]入力ミスのデータが表示されている',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '[売上進捗][営業ポイント成績表]レイアウト変更',
        sammery:
          'レスポンシブ対応に変更しました。ライブラリを導入したので全体的にレイアウト・デザインが今風なマテリアルデザインに統一されました。従来の見栄えと変わっていますので、改善点などありましたら都度対応していきますのでご意見ください',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '[売上進捗]支店別のすけさん売上、内訳を非表示に変更',
        sammery: '支店もチーム同様に支店中のすけさん売上と売上の内訳を表示していましたが、非表示になりました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '[売上進捗]旅館事業部の支店サマリは非表示にする',
        sammery: '旅館事業部は便宜上のチームを設けており、そこから予算、売上を登録するためレイアウト調整目的で本仕様に変更しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },
  {
    title: '',
    version: '1.1.8',
    tags: [],
    date: moment('2022-04-06T10:58:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '営業ダッシュボードにキャンセルされた物件は表示させないようにする',
        sammery:
          'アポ一覧にキャンセルされた物件が表示されていましたので修正しました。キャンセルされた物件は業務フロー画面のプロセスにキャンセルされた物件が溜まっていますので、そこから確認できます。',
        bugZillaTickets: [],
        openProjectTickets: [458, 459],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'すけさん売り上げ実績の集計方法修正',
        sammery:
          'すけさん実績は取得日前日24時未満で取得するようにしました。※これまでは指定付きの末日まで取っていましたので値が誤っていました。',
        bugZillaTickets: [],
        openProjectTickets: [460],
        memo: '',
      },
    ],
  },
  {
    title: '売上進捗管理表機能、契約書類提出管理機能、バグ対応、他微調整・ブラッシュアップ',
    version: '1.1.7',
    tags: [],
    date: moment('2022-03-30T11:58:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '*売上進捗管理表',
        sammery: '全体、支店別の売上進捗管理エクセルの機能を新規実装しました',
        bugZillaTickets: [],
        openProjectTickets: [373, 394, 404, 407, 435, 436, 437, 439, 442],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '契約書類提出管理機能',
        sammery:
          'フロー管理内に、契約書類提出状況を登録し把握管理できる機能を追加しました。**受注になったデモ**に対して必要な書類が提出されているかを一覧し、業務に提出された日もしくは「不要」（ワイドネット等のリプなどで回収不要な場合に対応）を登録できます。',
        bugZillaTickets: [],
        openProjectTickets: [355],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '現金・レンタルの場合、請求書発行と入金を登録したい',
        sammery: '現金・レンタルの場合は請求書発行で売上に計上するため、請求書の発行日と入金日の登録ができるようになりました',
        bugZillaTickets: [],
        openProjectTickets: [377],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '受注に対して、任意の月に売上計上させる為の項目と処理追加',
        sammery:
          'デモ結果の情報に「売上計上予定月」を新設しました。未設定の場合は売上計上はリース時：入金日、現金・レンタル：請求日になる野に対して、設定時はその指定した月で売上が計上されます（内部的には指定月の１日になります）',
        bugZillaTickets: [],
        openProjectTickets: [385],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業フロー>デモOK以降のリストの「売上合計」計算式に関して',
        sammery:
          '・リースの場合：( 受注金額÷料率(%) ) － 残債(ある場合)<br />・現金の場合：受注金額 － 残債(ある場合)<br />・レンタルの場合：受注金額 × 支払い回数(選択した期間に対応した回数) － 残債(ある場合) ※いずれの場合も、初期費用は計算しない',
        bugZillaTickets: [],
        openProjectTickets: [407],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '集計に乗らないダミー情報を登録できる宿を登録し、本番運用のテストで使用したい',
        sammery: '施設情報の編集画面にある施設種別（ホテル・旅館など）に「ダミー」を追加しました。これに選択された施設は集計に上がりません',
        bugZillaTickets: [],
        openProjectTickets: [410],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業フロー報告>入金開始**の項目名変更→「入金確認**」',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [415],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'リース売上金額の算出、10の位以下を切り捨てとする',
        sammery: '修正しました また、現金・レンタルで受注金額に期間月数をかけていた式を修正しました',
        bugZillaTickets: [],
        openProjectTickets: [418],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'アポ、デモで入力間違いを「入力ミス」として処理したい',
        sammery:
          'アポ・デモの編集画面の「報告区分」に入力ミス（非表示処理）を選択し登録します。営業処理から非表示となり集計から除外されます。フロー処理に入力ミスのプロセスを追加しましたので、そちらから参照できるようになりました',
        bugZillaTickets: [],
        openProjectTickets: [420],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'デモ受注後のキャンセルを登録したい',
        sammery:
          'デモ編集に別途「キャンセル日」の項目を設けました。受注＋キャンセルの登録も、通常のデモキャンセルと同様に扱えるようになりました',
        bugZillaTickets: [],
        openProjectTickets: [421],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '「すけさん」の実績を手入力でも可能にしたい',
        sammery:
          'チームの月合計として「チーム編集」にある予算登録の機能の実績登録に実装しました。使い方は"Web"、"その他"と同様です。データもそれらと同じように伝票に<strong>月合計</strong>として登録されます。<br /><br />関連:すけさん売上読み込みを軽量化するため、「すけさん実績更新」機能を「売上進捗」に実装しています。こちらを使用するとRSViewerのDBを読み込み本システムの伝票に<strong>日別・チーム別</strong>に集計されて登録されます。「すけさん実績更新」を行うたびに、この伝票データの金額と<strong>日別・チーム別</strong>に集約されたRSViewerの金額が異なっている場合データは更新（削除し新規登録）されます。',
        bugZillaTickets: [],
        openProjectTickets: [425],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '売上進捗>各チームの集計、売上見込み列の「レンタル」「WEB」「その他」の値を実績と同数反映させる',
        sammery:
          'チームの予算登録画面に「予算を実績の値で更新」ボタンを新設しました。押下し了承すると実績値の値が反映するようになりました。反映されるのは"システム:新規","システム:リプ"以外の手入力できる4項目です。',
        bugZillaTickets: [],
        openProjectTickets: [443],
        memo: '',
      },

      {
        product: '',
        title: 'ユーザダッシュボードで自分に関係する支店の情報をすべて表示させる',
        sammery: '代表となる支店情報での表示から、社員情報編集で関連付けた支店の情報を表示させます。',
        bugZillaTickets: [],
        openProjectTickets: [447],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '取材完了時のリース用紙回収区分がメール本文に載ってこなかった',
        sammery: '修正しました。設定した値に対する文言が登録されていませんでした。',
        bugZillaTickets: [],
        openProjectTickets: [448],
        memo: '',
      },

      {
        product: '',
        title: '支店の並び替え機能、ダミー支店追加',
        sammery: 'マスタの支店一覧で並び順ができるようになりました。また、ダミーとして売上に計上しない支店の登録ができるようになりました。',
        bugZillaTickets: [],
        openProjectTickets: [449],
        memo: '',
      },

      {
        product: '',
        title: 'メール本文のレイアウトを変更しました',
        sammery: '修正しました。変更後のレイアウトはOpenProjectのチケットから確認できます',
        bugZillaTickets: [],
        openProjectTickets: [450],
        memo: '',
      },

      {
        product: '',
        title: '施設売上の入力に対応',
        sammery: '旅館事業部の予算・実績を入力できるようになりました。入力も進捗表示も旅館事業部用の表示を適用しています。',
        bugZillaTickets: [],
        openProjectTickets: [452],
        memo: '',
      },
      {
        product: '',
        title: '売上進捗の「修正予算」項目は設定時のみ表示させる',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [453],
        memo: '',
      },

      {
        product: '',
        title: '売上進捗、各内訳にデモ編集へのリンクを設置する',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [454],
        memo: '',
      },

      {
        product: '',
        title: '売上進捗の新規売上とリプレイス売上の区分けと受注区分の関係変更',
        sammery:
          '受注区分が「新規」「元客」「バージョンアップ」のものは新規、「リプレイス」はそのままリプレイスのみを対象に集計されるようになりました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        product: '',
        title: '売上見込み・売上実績の料率参照変更のタイミングの調整変更',
        sammery:
          'デモ後、リース審査結果を登録した際に料率の設定が行われた場合、以後、<strong>デモ時の料率ではなくリース審査時の料率を参照する</strong>ようになります。売上見込みなどの金額計算に影響しますのでご留意ください。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        product: '',
        title: '売上進捗のレンタル・WEB・その他の<strong>売上見込み</strong>に売上実績を表示してほしい',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        product: '',
        title: 'デモ結果の確定した値の表示変更',
        sammery: '売上区分に関して入力した値をすべて表示させるようにしました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        product: '',
        title: 'デモ結果の金額チェック機構の変更',
        sammery:
          '以前の変更ですけさん受注に対応させるため空を許容するように変更されましたが、今回のバージョンからリース・レンタル・現金の場合必須となりました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        product: '',
        title: 'テストメールを送信したい',
        sammery: '社員情報編集に登録したメールアドレスに送信できるかを確認するためのテストメール送信機能を追加しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        product: '',
        title: 'リース審査時のメールフォーマット変更',
        sammery: '支店、アポ担当、デモ担当、売上金額が追加されました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '支店とチームもダミーデータとして集計に上がらないようにしたい',
        sammery:
          '支店編集を追加し名前の変更とダミーを登録できるようにしました。チームにもダミーを登録できるようにしました。暫定画面のためレイアウト等は以降の順次アップデートで改善していきます',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        product: '',
        title: 'ブラッシュアップ',
        sammery: '若干、レイアウトのブラッシュアップを行いました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },
  {
    title: 'バグ対応、他微調整・ブラッシュアップ',
    version: '1.1.6',
    tags: [],
    date: moment('2022-03-14T10:00:00').format(),

    sammery: '以下の機能を実装しました',
    issues: [
      {
        no: '',
        product: '',
        title: '再デモを行った情報がアポ一覧に表示されない',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [367],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'アポ情報からアポインター、アポチームは空許容に変更',
        sammery:
          'アポ獲得した支店は必須のままですが、アポインター、アポチームは空を許容できるようになり、アポの評価を個人に割り振らないケースに対応できるようになります。この変更により<strong class="red--text">ダッシュボードで表示される物件は自分自身が所属する支店のものが表示される</strong>ように変更されました',
        bugZillaTickets: [],
        openProjectTickets: [374],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '[フロー]デモ日程確認待ちの絞り込み条件修正',
        sammery:
          'デモを行ったアポ（再デモはのぞく）は対象から外れるように修正されました。※日程確認が行われなくてもデモが行われていればこのプロセスには溜まらなくなります',
        bugZillaTickets: [],
        openProjectTickets: [378],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '[フロー]リース開始待ちの絞り込み条件修正',
        sammery:
          '<strong>入金</strong>にあるものは、導入取材報告済みで絞り込まれるように変更しました。ex: 入金確認待ち・・・導入取材が完了していて未入金のもの',
        bugZillaTickets: [],
        openProjectTickets: [379],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'リース審査にかかわる処理を現在の実務に合わせた流れにしてほしい',
        sammery:
          '審査提出→審査結果登録の流れを廃止し、受注OKでリース結果未登録の物件を「リース審査中」として抽出します。審査結果を登録するため、審査結果日・審査結果・リース会社・料率の登録 を必須項目と設定しました。また、設定した条件によって編集不可となる項目を設定しました。フロー中の「リース審査OK」プロセスが不要になったため消しました',
        bugZillaTickets: [],
        openProjectTickets: [380, 388],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '[フロー]項目名、「導入・取材待ち」を「導入日決定物件」に変更してほしい',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [381],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '入金確認日、予定日等が入力した日付から1日分ずれて（マイナスになって）登録される',
        sammery: '時差処理を適応させ修正しました',
        bugZillaTickets: [],
        openProjectTickets: [382],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '売上見込み金額の10の位が「繰り上がって」しまっている。正しくは10の位で「切り捨て」',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [383],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'リース開始、リース入金確認に関するメールの仕様変更',
        sammery: 'リース入金確認時のメール送信は不要。リース開始のメールフォーマットを変更',
        bugZillaTickets: [],
        openProjectTickets: [386],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '営業フロー報告にて展開する画面に「見出し」を追加',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [387],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '営業フロー報告にてリース開始待ち画面「リース開始」にて編集可能な項目の整理',
        sammery:
          'リース開始の場合、入金確認・入金確認日・確定金額・NG確認 を非表示にしました。また<br />・操作の項目名「リース開始」→「リース開始登録」<br />・報告画面の見出し「リース情報修正」→「リース開始登録」<br />・報告画面内の項目「リース開始確認」→「リース開始確認日」<br />に変更ました',
        bugZillaTickets: [],
        openProjectTickets: [388],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '売上区分の名称変更「マージン」→「手数料」、メール本文の記述も同様に変更',
        sammery: '変更しました',
        bugZillaTickets: [],
        openProjectTickets: [389],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'デモ結果の「受注金額」の必須を外す',
        sammery: 'すけさんのみの受注に対応できるようになりました',
        bugZillaTickets: [],
        openProjectTickets: [390],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '導入・取材待ちリストの「導入取材報告」フォームのタイトル、項目名がゆらいでいるので統一化',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [393],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '入金確認待ち>「入金登録」押下後画面の修正',
        sammery:
          '指定された条件によって表示されるフォームのタイトルが変わるようになりました。また入金NGを備考のあとに移動させ、入金日登録に関する一連の操作に入り込まないようUIを配慮しました',
        bugZillaTickets: [],
        openProjectTickets: [398],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'デモ編集にて、リースから現金・レンタルに変えた場合「料率」の値が残っている模様',
        sammery: 'リース以外は料率設定を空とさせ、売り上げ見込みは金額と期間で産出するように変更しました',
        bugZillaTickets: [],
        openProjectTickets: [399],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: ' [営業]アポ、デモのいずれの内容からキャンセル済みは表示させない',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [401],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: ' 営業フロー報告>リース審査中画面>審査結果登録画面にて、情報入力後右上の「登録」を押下するとエラー',
        sammery: '修正しました',
        bugZillaTickets: [],
        openProjectTickets: [402],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '導入日未定画面にリストアップするルール 現状：リース審査中もリストアップされている',
        sammery: '修正しました 現金・レンタルとなった物件も表示されます',
        bugZillaTickets: [],
        openProjectTickets: [404],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '導入取材日程変更で変更した情報が反映しない',
        sammery: 'バグが再現されませんでした',
        bugZillaTickets: [],
        openProjectTickets: [408],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'リース登録などのデモ情報表示に契約期間を表示',
        sammery: 'デモ情報に契約期間を表示されるようにしました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: 'アポ情報のアポ担当、チームに「指定なし」と設定できるようにしたい',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },

      {
        no: '',
        product: '',
        title: '[フロー]「リース用紙回収日」を廃止',
        sammery: '要求にない仕様となりましたので廃止されました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '[フロー]「新規アポ取得」「即デモ結果入力」を廃止',
        sammery: 'ユーザダッシュボードから完全に行うようになったため、本ページのこれら機能は不要となり廃止になりました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'オープンプロジェクトのワークパッケージリンク追加',
        sammery:
          '新しい社内プロジェクト管理として採用された<a href="http://54.249.226.3:8080/" target="_blank">オープンプロジェクト</a>のワークパッケージに対してissueの番号をリンクできるようにしました。bugzillaへのリンクも従来通り使用できます。リンク色はopenProjectとbugzillaで（見分けがつきにくいですが一応）分けてあります',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'ヘルプページ',
        sammery:
          'マニュアル等の操作補助ページを準備しました。順次増やしていきますが今回は修正内容にあった「売上見込み金額」をデバッグ用で使用していたものを設置し、シュミレートできる計算機をおいてみました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'ブラッシュアップ',
        sammery: '処理の見直し、レイアウト修正、ソース整理等のブラッシュアップを行いました。',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },
  {
    title: 'バグ対応、微調整',
    version: '1.1.5',
    tags: [],
    date: moment('2022-03-07T10:53:00').format(),

    sammery: '以下のバグ対応、微調整を行いました',
    issues: [
      {
        no: '',
        product: '',
        title: '営業活動のリストで屋号が見切れる',
        sammery: 'レイアウトを変更しました レスポンシブ対応し今まで右にあったボタンがリストの下段に移動されるようになりました',
        bugZillaTickets: [7821],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'ログイン時Enterでログイン処理されない',
        sammery: '対応しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '電話番号入力チェック改修',
        sammery: '全角で入力できてしまう件を修正しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '再デモがデモ結果に登録されない',
        sammery:
          'デモ結果に「再デモ」として登録され、デモ日程確認に"再デモ"として表示されるようになります。以後同じ流れでデモ結果等登録できます。デモ結果は上書きされるため、再デモの記録が必要な場合備考を活用してください',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '文言の変更',
        sammery: '即デモ → 即デモ結果 再デモ → 再デモ結果 返事→返事結果',
        bugZillaTickets: [],
        openProjectTickets: [366],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'その他レイアウト調整',
        sammery: '文字の大きさ、配置、デザインが統一されていなかった箇所など調整しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },
  {
    title: '微調整',
    version: '1.1.4',
    tags: [],
    date: moment('2022-03-04T00:00:00').format(),

    sammery: '既出のバグへの修正対応、機能追加',
    issues: [
      {
        no: '',
        product: '',
        title: 'Cookieが保存されたない件 ',
        sammery: '保持機構を変更し、利用箇所の仕様を変更させ対応しました',
        bugZillaTickets: [7800],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '社員マイページ修正 ',
        sammery: '確認できる登録項目の拡張（支店、チーム、メール配信先のアドレス）、パスワード変更機構',
        bugZillaTickets: [7801],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'リリースノート追加 ',
        sammery: 'リリースノートページ追加',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'フローに金額集計機能追加 ',
        sammery:
          'デモの受注金額データがあるものは表示させ、その合計値をデータテーブル下部に表示させました   \n <span class="red--text">表示件数は[All]を初期値とし、集計値は表示件数や表示内容と連動はしていません</span>',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '導入取材報告に「未回収」がなかったため追加 ',
        sammery: '追加しました',
        bugZillaTickets: [7814],
        openProjectTickets: [353],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '宿の電話番号チェック、登録方法を変更 ',
        sammery:
          '新規で登録していた場合でも電話番号が同じであれば自動で既存宿情報に紐づきます。入力自に電話番号のフォーマットチェックを行うようにしています。ハイフン抜きで入力していただきたいのですが、万が一ハイフンをつけて入力した場合でも内部で消す処理を行うため表示時にハイフンが消されて表示されます',
        bugZillaTickets: [7813],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'パスワード変更機能',
        sammery:
          '自分自身でパスワードを変更できるようにしました。また、管理者によってパスワードの変更（上書き）が行えるようになりました。セキュリティ強度を表示しています',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'レイアウト調整',
        sammery: '社員情報編集、予算設定などの次世代開発部分の配置や表示方法を見直し調整しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },
  {
    title: '微調整',
    version: '1.1.3',
    tags: [],
    date: moment('2022-03-01T00:00:00').format(),

    sammery: '初回リリース後に見つかった微細なバグ、仕様変更対応',
    issues: [
      {
        no: '',
        product: '',
        title: '新規アポ追加のレイアウト変更 ',
        sammery: '新規アポ取得時の操作で、"デモ日程確認日"を誤って登録してしまう件に対応。新規アポ登録の順番から除外し別項目にしました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: '社員のチーム登録を空登録可能 ',
        sammery: 'チーム不明時のメール配信先登録可能へ対応しました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
      {
        no: '',
        product: '',
        title: 'リース提出時に、リース審査結果の項目追加',
        sammery: 'リース審査結果も同時に登録できるようになりました',
        bugZillaTickets: [],
        openProjectTickets: [],
        memo: '',
      },
    ],
  },

  {
    title: '初回リリース',
    version: '1.1.0',
    tags: [],
    date: moment('2022-02-28').format(),

    sammery: '営業報告関連機能',
    issues: [],
  },
];

export default releases;
