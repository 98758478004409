<template>
  <v-container fluid>
    <v-list dense>
      <v-subheader>リリースノート</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item v-for="(release, index) of releases" :key="index">
          <v-list-item-icon>
            <v-row>
              <v-col>
                <div class="text-caption font-weight-bold">{{ release.date | date('YYYY.MM.DD') }}</div>
                <div class="mt-4"><v-icon v-text="'mdi-tag-outline'" small /> {{ release.version }}</div>
              </v-col>
            </v-row>
          </v-list-item-icon>
          <v-list-item-content>
            <v-card outlined>
              <v-card-title>
                {{ release.title }}
                <v-spacer />
                <v-chip v-if="index == 0" color="success" outlined small>Latest</v-chip>
              </v-card-title>
              <v-card-subtitle>version {{ release.version }} </v-card-subtitle>
              <v-card-subtitle v-if="release.tags.length > 0" class="pa-0 ml-4 mt-n2">
                <v-icon v-text="'mdi-tag-multiple-outline'" /> {{ release.tags | implode }}
              </v-card-subtitle>
              <v-divider />
              <v-card-text>
                <div v-html="release.sammery" />

                <v-list-item v-for="(issue, j) in release.issues" :key="j">
                  <!-- <v-list-item-avatar>
                    {{ issue.no }}
                  </v-list-item-avatar> -->
                  <v-list-item-content>
                    <v-list-item-title class="text-caption font-weight-bold">
                      <div class="d-flex justify-start">
                        <!-- <v-chip outlined small>{{ issue.no }}</v-chip> -->
                        <v-chip outlined small>{{ ('00' + (j + 1)).slice(-2) }}</v-chip>
                        <span class="mt-1 ml-2" v-html="$options.filters.md(issue.title)" />
                      </div>
                    </v-list-item-title>
                    <v-list-item-content>
                      <p class="text-caption mt-n2" v-html="$options.filters.md(issue.sammery)" />
                    </v-list-item-content>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-chip
                      class="ma-1 white--text"
                      color="#404D6C"
                      v-for="(ticket, l) in issue.bugZillaTickets"
                      :key="`bugZilla${l}`"
                      :href="getBugzilla(ticket)"
                      target="_blank"
                      small
                    >
                      #{{ ticket }}
                    </v-chip>
                    <v-chip
                      class="ma-1 white--text"
                      color="#1A67A3"
                      v-for="(ticket, k) in issue.openProjectTickets"
                      :key="`openProject${k}`"
                      :href="getOpenProject(ticket)"
                      target="_blank"
                      small
                    >
                      #{{ ticket }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>
<script>
import releases from '@/releaseNote';
export default {
  components: {},
  computed: {},

  props: {},

  data: () => ({
    initializing: true,

    releases: releases,
  }),
  methods: {
    onLoaded() {
      this.initializing = false;
    },

    getBugzilla(ticket) {
      return `http://bugzilla.liberty-hp.com/show_bug.cgi?id=${ticket}`;
    },
    getOpenProject(ticket) {
      return `http://54.249.226.3:8080/projects/ye-wu-guan-li-turu/work_packages/${ticket}`;
    },
  },
  watch: {},
  mounted() {
    this.onLoaded();
  },
};
</script>
